import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  font-family: ASThermal;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const LogoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: block;
  align-self: center;
  opacity: 0;
  transition: opacity 2s linear;
  z-index: 1;
  mix-blend-mode: difference;

  ${({ animate }) => animate && `
    opacity: 1;
  `}
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  position: relative;
  /*display: flex;
  flex-direction: column;
  justify-content: space-between;*/
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const TextTitle = styled.p`
  font-size: 12px;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
`;

export const TextSubTitle = styled.p`
  font-weight: 500;
  font-size: 12px;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
`;

export const TextDescription = styled.p`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  align-self: center;
  opacity: 0;
  transition: opacity 2s linear;
  padding: 15px;
  width: 100%;
  text-align: center;
  color: white;
  mix-blend-mode: difference;
  z-index: 1;

  ${({ animate }) => animate && `
    opacity: 1;
  `}
`;

export const Menu = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 2s linear;
  padding: 15px;
  mix-blend-mode: difference;

  ${({ animate }) => animate && `
    opacity: 1;
  `}
`;

export const MenuIntro = styled.div`
  display: block;

  @media (max-width: 1023px) {
    flex: 1;
  }

  @media (min-width: 1024px) {
    width: 40vw;
  }
`;

export const MenuIntroLinkWrap = styled.div`
  position: relative;
  display: block;
  padding-top: 16px;
`;

export const MenuIntroLink = styled.a`
  display: block;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  
  &:last-of-type {
    padding-top: 16px;
  }
`;

export const MenuIntroItem = styled.div`
  color: inherit;
  position: relative;
  display: block;
`;

export const MenuNav = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MenuNavItem = styled.div`
  text-transform: uppercase;
  padding-bottom: 32px;
  cursor: pointer;

  @media (min-width: 1024px) {
    padding-bottom: 16px;
  }
`;

export const MenuNavRadio = styled.input`
  display: none;

  &:checked + label {
    opacity: 1;
  }
`;

export const MenuNavRadioLabel = styled.label`
  position: relative;
  opacity: 0.4;
  cursor: pointer;
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

export const StyledButtonWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  width: 100%;
  height: 46px;
  z-index: 1;
  padding: 15px;
  text-align: center;
  mix-blend-mode: difference;
`;

export const StyledButton = styled.div`
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
`;

export const StyledButtonTitle = styled.h3`
  position: relative;
  font-family: 'Correspondance';
  cursor: pointer;
  font-weight: normal;
`;

export const Canvas = styled.canvas`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  ${({ hidden }) => hidden && `
    display: none;
  `}
`;

export const VideoWrapepr = styled.div`
  position: relative;
  display: block;
  height: 100vh;
  width: 100%;
`;

export const Video = styled.video`
  position: absolute;
  display: block;
  height: 100%;
  width: auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1023px) {
    height: auto;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
  }

  ${({ hidden }) => hidden && `
    display: none;
  `}
`;


