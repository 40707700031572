import { createGlobalStyle } from 'styled-components';

import ASThermalRegularWoff from './ASThermal-Regular.woff';
import ASThermalRegularWoff2 from './ASThermal-Regular.woff2';
import CorrespondanceWoff from './Correspondance.woff';
import CorrespondanceWoff2 from './Correspondance.woff2';

export default createGlobalStyle`
    @font-face {
      font-family: 'ASThermal';
      src: local('ASThermal'),
      url(${ASThermalRegularWoff2}) format('woff2'),
      url(${ASThermalRegularWoff}) format('woff');
    }

    @font-face {
      font-family: 'Correspondance';
      src: local('Correspondance'),
      url(${CorrespondanceWoff2}) format('woff2'),
      url(${CorrespondanceWoff}) format('woff');
    }
`;